#root
  > div:nth-child(2)
  > div
  > div.p-datatable.p-component.p-datatable-responsive-scroll.p-datatable-striped
  > div.p-datatable-wrapper
  > table
  > thead
  > tr
  > th:nth-child(n + 1):nth-child(-n + 7)
  > div
  > span:nth-child(2)
  > svg {
  color: gainsboro;
}
